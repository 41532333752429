import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Navbar from "components/Navbar";
import Collapse from "components/Collapse";
import Nav from "components/Nav";
import Dropdown from "components/Dropdown";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "navbar"
    }}>{`Navbar`}</h1>

    <Playground __position={0} __code={'() => {\n  const [isOpen, setIsOpen] = React.useState(false)\n  const toggle = () => setIsOpen(!isOpen)\n  return (\n    <Navbar color=\"light\" light expand=\"md\">\n      <Navbar.Brand href=\"/\">reactstrap</Navbar.Brand>\n      <Navbar.Toggler onClick={toggle} />\n      <Collapse isOpen={isOpen} navbar>\n        <Nav className=\"mr-auto\" navbar>\n          <Nav.Item>\n            <Nav.Link href=\"#\">Components</Nav.Link>\n          </Nav.Item>\n          <Nav.Item>\n            <Nav.Link href=\"#\">GitHub</Nav.Link>\n          </Nav.Item>\n          <Dropdown nav inNavbar uncontrolled>\n            <Dropdown.Toggle nav caret>\n              Options\n            </Dropdown.Toggle>\n            <Dropdown.Menu right>\n              <Dropdown.Item>Option 1</Dropdown.Item>\n              <Dropdown.Item>Option 2</Dropdown.Item>\n              <Dropdown.Item divider />\n              <Dropdown.Item>Reset</Dropdown.Item>\n            </Dropdown.Menu>\n          </Dropdown>\n        </Nav>\n        <Navbar.Text>Simple Text</Navbar.Text>\n      </Collapse>\n    </Navbar>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Navbar,
      Collapse,
      Nav,
      Dropdown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = React.useState(false);

        const toggle = () => setIsOpen(!isOpen);

        return <Navbar color="light" light expand="md" mdxType="Navbar">
        <Navbar.Brand href="/">reactstrap</Navbar.Brand>
        <Navbar.Toggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar mdxType="Collapse">
          <Nav className="mr-auto" navbar mdxType="Nav">
            <Nav.Item>
              <Nav.Link href="#">Components</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#">GitHub</Nav.Link>
            </Nav.Item>
            <Dropdown nav inNavbar uncontrolled mdxType="Dropdown">
              <Dropdown.Toggle nav caret>
                Options
              </Dropdown.Toggle>
              <Dropdown.Menu right>
                <Dropdown.Item>
                  Option 1
                </Dropdown.Item>
                <Dropdown.Item>
                  Option 2
                </Dropdown.Item>
                <Dropdown.Item divider />
                <Dropdown.Item>
                  Reset
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <Navbar.Text>Simple Text</Navbar.Text>
        </Collapse>
      </Navbar>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/navbar/" target="_blank">Reactstrap Navbar</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      